/**
 * @ComponentFor AppShellViewModel
 */
import React from 'react';
import { styled, applyClassName } from '@glitz/react';
import { translate } from '@avensia/scope';
import { Main, Part, Section, Appearance as PageAppearance } from 'Shared/PageLayout';
import Logotype from 'Shared/Logotype';
import { PageType } from 'Shared/State';
import * as style from 'Shared/Style';
import PageNotFound from 'Error/Pages/PageNotFound';

const Center = styled(Main, {
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  justifyContent: 'center',
});

const Splash = styled(applyClassName(Logotype), {
  display: 'block',
  maxWidth: '20rem',
  maxHeight: '10rem',
  margin: { x: 'auto', y: 'auto' },
  color: style.monochrome,
});

// This component is rendered when the visitor has a service worker enabled browser
// and she is making a full page load for a url that we don't have in the local cache.
// So the app shell (header and footer) will be rendered and this component gets
// rendered during the time we load the page she wanted.
export default class AppShell extends React.Component<PageType> {
  render() {
    return !this.props.isLoading && this.props.loadFailure && this.props.loadFailure.status === 404 ? (
      <PageNotFound />
    ) : (
      <Center appearance={PageAppearance.Narrow}>
        {this.props.isLoading ? (
          <Part>
            <Splash />
          </Part>
        ) : !navigator.onLine ? (
          <Section>
            <h1>{translate('/Errors/OfflineHeader')}</h1>
            <p>{translate('/Errors/OfflineText')}</p>
          </Section>
        ) : (
          <Section>
            <h1>{translate('/Errors/UnknownLoadFailureHeader')}</h1>
            <p>{translate('/Errors/UnknownLoadFailureText')}</p>
          </Section>
        )}
      </Center>
    );
  }
}
